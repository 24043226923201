import useUTMHandler, { useGetUtmSessionTags } from '@hooks/useUTMtags';
import { useEffect } from 'react';
import { useUserData } from 'state/userData';

const eventName = 'pageView';

const UtmTagsProvider = () => {
    const { utm_source, utm_medium, utm_campaign } = useGetUtmSessionTags();

    const { user } = useUserData();
    const userId = user?.id;

    useUTMHandler();

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            event: eventName,
            value: userId,
            utm_source,
            utm_medium,
            utm_campaign,
        });
    }, [utm_campaign, utm_source, utm_medium]);

    return null;
};

export default UtmTagsProvider;
