import Script from 'next/script';
import useClientRuntimeConfig from 'state/useClientRuntimeConfig';
import { getCachedRuntimeConfig } from 'utils/runtimeConfigUtils';

const cashedConfig = getCachedRuntimeConfig();
const { NEXT_PUBLIC_GTM_ID: CASHED_GTM_ID } = cashedConfig;

const GtmScript: React.FC = () => {
    const { config } = useClientRuntimeConfig();
    const { NEXT_PUBLIC_GTM_ID: CLIENT_GTM_ID } = config;

    const id = CLIENT_GTM_ID || CASHED_GTM_ID;

    if (!id) return null;

    const script = `(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${id}');`;

    return <Script id="GTMOnReadyScript">{script}</Script>;
};

export default GtmScript;
